<script lang="ts" setup>
import type { Games } from "@/types";

const { t } = useT();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();

defineEmits<{ (event: "click"): void }>();

const props = defineProps<{
	title?: string;
	logo?: string;
	games?: Games;
	slug?: string;
}>();

const isSvg = computed(() => props.logo?.trim()?.startsWith("<svg"));
</script>

<template>
	<div class="collection-card" @click="$emit('click')">
		<div class="title-flex">
			<AText
				class="title"
				variant="tulsa"
				:modifiers="['bold', 'uppercase']"
				as="h2"
				:data-tid="slug ? `${slug}-title` : null"
				>{{ title }}</AText
			>
			<i v-if="isSvg" class="icon text-primary-400" v-html="logo" />
			<NuxtImg
				v-else
				:src="`${baseImageUrl}${logo}`"
				class="icon"
				:width="108"
				:height="108"
				:alt="title"
				format="webp"
				loading="lazy"
			/>
		</div>
		<OGames :games="games || []" show="grid" hideFavoriteBtn />
		<AButton variant="outline" size="lg" :modifiers="['wide']" :data-tid="slug ? `${slug}-view-all` : null">
			<AText variant="toledo" :modifiers="['bold', 'uppercase']">{{ t("View games") }}</AText>
		</AButton>
	</div>
</template>

<style lang="scss" scoped>
.collection-card {
	background: var(--gradient-20);
	min-width: 332px;
	padding: 12px 16px 32px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	border-radius: 12px;
	.title-flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		.title {
			max-width: 135px;
		}
	}
	& :deep(.grid-cards) {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-gap: 10.5px;
		.card {
			padding-top: 97px;
		}
		.badge-wrap {
			z-index: 3;
			.prize.entries {
				gap: 2px;
				.nuxt-icon {
					font-size: 11px;
				}
				.ternopil {
					font-size: 7px;
				}
			}
		}
	}
}

.icon {
	width: 72px;
	height: 72px;
}
</style>
